@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:wght@300;400;500&display=swap');

html,
body,
#root {
    height: 100%;
    margin: 0;
}
body {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fafafa;
}
code,
pre {
    font-family: 'Roboto Mono', source-code-pro, monospace;
}

img,
.wistia_embed {
    max-width: 100%;
}

#teconsent {
    display: none !important;
}

@media print {
    #root > header {
        position: static;
    }
    #root > header > div > :not(:first-child),
    .MuiPopover-root,
    .MuiMenu-root,
    .MuiModal-root,
    #root > footer,
    #root > main#kb-article > :last-child {
        display: none;
    }
}
